<template>
  <section>
    <b-card no-body>
      <b-card-body>
        <h3 class="text-primary">
          Hạng Đồng
        </h3>
        <div class="divider" />
        <b-form-textarea
          placeholder="Mô tả"
          rows="5"
        />
        <div class="divider" />

        <b-row>
          <b-col md="3">
            <h4 class="text-primary">
              Điều kiện
            </h4>
          </b-col>
          <b-col md="9">
            <b-card class="mb-0">
              <b-row>
                <b-col md="6">
                  <b-row>
                    <b-col cols="12">
                      <b-form-group
                        label="First Name"
                        label-for="h-first-name"
                        label-cols-md="4"
                      >
                        <b-form-input
                          id="h-first-name"
                          placeholder="First Name"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group
                        label="Email"
                        label-for="h-email"
                        label-cols-md="4"
                      >
                        <b-form-input
                          id="h-email"
                          type="email"
                          placeholder="Email"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group
                        label="Mobile"
                        label-for="h-number"
                        label-cols-md="4"
                      >
                        <b-form-input
                          id="h-number"
                          type="number"
                          placeholder="Mobile"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group
                        label="Password"
                        label-for="h-password"
                        label-cols-md="4"
                      >
                        <b-form-input
                          id="h-password"
                          type="password"
                          placeholder="Password"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <div class="divider" />

        <b-row>
          <b-col md="3">
            <h4 class="text-primary">
              Hoa hồng cơ bản
            </h4>
          </b-col>
          <b-col md="9">
            <b-card class="mb-0">
              <b-row>
                <b-col md="3">
                  <b-form-group
                    label="Tỷ lệ phần trăm"
                  >
                    <b-input-group class="input-group-merge">
                      <b-form-input
                        placeholder="Tỷ lệ %"
                        class="text-right"
                      />
                      <b-input-group-append is-text>
                        %
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group
                    label="Tỷ lệ phần trăm"
                  >
                    <b-input-group class="input-group-merge">
                      <b-form-input
                        placeholder="Tỷ lệ % VNĐ"
                        class="text-right"
                      />
                      <b-input-group-append is-text>
                        ₫
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <div class="divider" />

        <b-row>
          <b-col md="3">
            <h4 class="text-primary">
              Phần thưởng
            </h4>
          </b-col>
          <b-col md="9">
            <b-card class="mb-0">
              <b-row
                v-for="(item, index) in vouchers"
                :key="index"
                class="mb-1"
              >
                <b-col md="3">
                  <b-badge
                    variant="light-primary"
                    class="d-flex align-items-center text-left h-100"
                  >
                    <feather-icon
                      icon="XCircleIcon"
                      class="mr-50"
                    />
                    <div
                      class="d-flex flex-column"
                      style="font-weight: 400;"
                    >
                      <strong>{{ item.title }}</strong>
                      <span>{{ item.content }}</span>
                    </div>
                  </b-badge>
                </b-col>
                <b-col md="3">
                  <b-form-spinbutton
                    v-model="item.quantity"
                    min="1"
                    max="100"
                  />
                </b-col>
              </b-row>

              <b-button
                v-b-modal.modal-add
                variant="light"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-50"
                />
                <strong>Thêm phần thưởng</strong>
              </b-button>
            </b-card>
          </b-col>
        </b-row>
        <div class="divider" />

        <div class="d-flex align-items-center">
          <b-button
            variant="light"
            class="mr-2"
          >
            Xem trước
          </b-button>
          <b-button
            variant="primary"
          >
            Lưu thay đổi
          </b-button>
        </div>
      </b-card-body>
    </b-card>

    <!-- ADD REWARD MODAL -->
    <b-modal
      id="modal-add"
      size="sm"
      centered
      title="Thêm phần thưởng"
      header-bg-variant="primary"
      header-text-variant="light"
      header-close-variant="danger"
      title-tag="h4"
    >
      <b-form-group
        label="Tên phần thưởng"
      >
        <b-form-input
          id="name"
          placeholder="Nhập tên hạng"
        />
      </b-form-group>

      <b-form-group
        label="Voucher"
      >
        <b-input-group>
          <b-input-group-prepend>
            <b-dropdown
              text="Giảm %"
              variant="outline-primary"
            >
              <b-dropdown-item>Giảm %</b-dropdown-item>
              <b-dropdown-item>Giảm $</b-dropdown-item>
            </b-dropdown>
          </b-input-group-prepend>
          <b-form-input placeholder="40.000 VNĐ" />
        </b-input-group>
      </b-form-group>

      <b-form-group
        label="Điều kiện"
      >
        <b-input-group>
          <b-input-group-prepend>
            <b-dropdown
              text="Đơn tối thiểu"
              variant="outline-primary"
            >
              <b-dropdown-item>Đơn tối thiểu</b-dropdown-item>
              <b-dropdown-item>Đơn tối thiểu</b-dropdown-item>
            </b-dropdown>
          </b-input-group-prepend>
          <b-form-input placeholder="40.000 VNĐ" />
        </b-input-group>
      </b-form-group>

      <b-form-group
        label="Hết hạn"
      >
        <flat-pickr
          v-model="date"
          class="form-control"
          :config="{ dateFormat: 'd-m-Y' }"
          placeholder="DD-MM-YYYY"
        />
      </b-form-group>

      <template #modal-footer="{ ok }">
        <b-button
          variant="primary"
          class="w-100"
          @click="ok()"
        >
          Thêm phần thưởng
        </b-button>
      </template>
    </b-modal>
  </section>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSpinbutton,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormTextarea,
  BBadge,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'

export default {
  name: 'RankSegmentEdit',
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSpinbutton,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormTextarea,
    BBadge,
    BDropdown,
    BDropdownItem,
    flatPickr,
  },
  props: {},

  data() {
    return {
      vouchers: [
        {
          title: 'Mã Freeship',
          content: 'Tối đa 20K',
          quantity: 1,
        },
        {
          title: 'Giảm 50K',
          content: 'Đơn tối thiểu 300K',
          quantity: 1,
        },
      ],
      date: null,
    }
  },

  methods: {},
}
</script>

<style lang="sass" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss'

.card .card
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important
</style>
